// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beer-js": () => import("./../../../src/pages/beer.js" /* webpackChunkName: "component---src-pages-beer-js" */),
  "component---src-pages-cars-js": () => import("./../../../src/pages/cars.js" /* webpackChunkName: "component---src-pages-cars-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-equipment-js": () => import("./../../../src/pages/equipment.js" /* webpackChunkName: "component---src-pages-equipment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-miramonti-js": () => import("./../../../src/pages/miramonti.js" /* webpackChunkName: "component---src-pages-miramonti-js" */),
  "component---src-pages-wine-js": () => import("./../../../src/pages/wine.js" /* webpackChunkName: "component---src-pages-wine-js" */)
}

